import { addNotify } from "features/notify";

export const actionTypes = {
  GET_TRANSFERS: "bets/GET_TRANSFERS",
};

export const getTransfers = (data) => async (dispatch, getState, extra) => {
  const {locale} = getState().locale;
  const {api} = extra;
  const response = await api.bets.getTransfers(data);
  const errorMessage = locale.hasOwnProperty(response.codeStatus)
    ? locale[response.codeStatus]
    : locale.defaultMessage;
  response.success
    ? dispatch({type: actionTypes.GET_TRANSFERS, payload: response.data})
    : dispatch(addNotify(errorMessage, "error"));
};