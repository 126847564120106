export const allProviders = {
	slots: [
		'PRAGMATIC', 'EVOPLAY', 'OUTCOMEBET', 'EVOLUTION',
		'TVBET', 'FIABLE', 'BSW', 'BSWGAMES', 'BETRADAR',
		'INBET', 'SOFTGAMING', 'SLOTEGRATOR',
	],
	'live-casino': ['EZUGI', 'EVOLUTION'],
	sports: ['LINE1X'],
	all() {
		return [...new Set([...this.slots, ...this['live-casino'], ...this.sports])]
	},
}