import React, { useState, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import SVGInline from 'react-svg-inline';

import Spinner from 'components/Spinner';
import Select from 'components/Select';
import Input from 'components/Input/desktop';
import Button from 'components/Button';
import calendarSVG from 'features/PaymentAdmin/img/calendar.svg';

import { actions as paymentAdminActions } from 'features/PaymentAdmin';

import BankWithdrawalsItem from './BankWithdrawalsItem';

import './BankWithdrawals.scss';

const b = block('bank-withdrawals');

const BankWithdrawals = () => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const banksRequisites = useSelector(state => state.paymentAdmin.banksRequisites);
  const { bankWithdrawals, actionProcessing } = useSelector(state => state.paymentAdmin);

  const banks = useMemo(() => {
    return Object.keys(banksRequisites).reduce(
      (acc, bank) => {
        acc.push({ name: bank, value: bank });
        return acc;
      },
      [{ name: locale.allBanks, value: '' }],
    );
  }, [banksRequisites, locale]);

  const statuses = useMemo(
    () => [
      { value: null, name: locale.allStatuses },
      { value: 0, name: locale.created },
      { value: 1, name: locale.approved },
      { value: 2, name: locale.canceled },
    ],
    [locale],
  );

  const [filters, setFilters] = useState({
    status: statuses[0].value,
    bank: banks[0].value,
    id: '',
    playerId: '',
    amount: '',
  });

  const [startDate, setStartDate] = useState(dayjs().add(-1, 'month').$d);
  const [endDate, setEndDate] = useState(dayjs().$d);

  const [trigger, setTrigger] = useState({});

  const activeBank = banks.find(({ value }) => value === filters.bank) ?? banks[0].value;
  const activeStatus = statuses.find(({ value }) => value === filters.status) ?? statuses[0].value;

  useEffect(() => {
    dispatch(paymentAdminActions.getRequisites());
  }, [dispatch]);

  useEffect(() => {
    dispatch(paymentAdminActions.getBankWithdrawals({ status: filters.status }));
  }, []);

  const withdrawals = useMemo(
    () =>
      bankWithdrawals
        .filter(item => {
          const { status, bank, id, amount, playerId } = filters;
          return (
            (status !== null ? item.status === Number(status) : true) &&
            (bank ? item.bank === bank : true) &&
            (id ? item.id === Number(id) : true) &&
            (playerId ? item.player_id === Number(playerId) : true) &&
            (amount ? item.amount === Number(amount) : true) &&
            dayjs(item.create_date) >= dayjs(startDate) &&
            dayjs(item.create_date) <= dayjs(endDate)
          );
        })
        .map(bw => (
          <div className={b('item')} key={bw.id}>
            <BankWithdrawalsItem item={bw} />
          </div>
        )),
    [bankWithdrawals, trigger],
  );

  return (
    <article className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('filters')}>
        <div className={b('dates')}>
          <div className={b('filter-item')}>
            <div className={b('filter-title')}>{locale.startDate}</div>
            <div className={b('input')}>
              <DatePicker
                selected={startDate}
                onChange={date => setStartDate(date)}
                selectsStart
                className={b('date')}
                startDate={startDate}
                dateFormat="yyyy-MM-dd"
                endDate={endDate}
              />
              <SVGInline svg={calendarSVG} className={b('calendar').toString()} />
            </div>
          </div>
          <div className={b('filter-item')}>
            <div className={b('filter-title')}>{locale.endingDate}</div>
            <div className={b('input')}>
              <DatePicker
                selected={endDate}
                onChange={date => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                className={b('date')}
                endDate={endDate}
                dateFormat="yyyy-MM-dd"
                minDate={startDate}
              />
              <SVGInline svg={calendarSVG} className={b('calendar').toString()} />
            </div>
          </div>
        </div>
        <div className={b('block')}>
          <div className={b('label')}>ID</div>
          <Input value={filters.id} onChange={e => setFilters({ ...filters, id: e.currentTarget.value })} />
        </div>
        <div className={b('block')}>
          <div className={b('label')}>{locale.userId}</div>
          <Input value={filters.playerId} onChange={e => setFilters({ ...filters, playerId: e.currentTarget.value })} />
        </div>
        <div className={b('block')}>
          <div className={b('label')}>{locale.amount}</div>
          <Input value={filters.amount} onChange={e => setFilters({ ...filters, amount: e.currentTarget.value })} />
        </div>
        <div className={b('block')}>
          <Select
            items={statuses}
            activeItem={activeStatus}
            onChange={value => setFilters({ ...filters, status: value })}
          />
        </div>
        <div className={b('block')}>
          <Select items={banks} activeItem={activeBank} onChange={value => setFilters({ ...filters, bank: value })} />
        </div>
        <div className={b('button')}>
          <Button text={locale.apply} callBack={() => setTrigger({})} />
        </div>
      </div>
      <div className={b('list')}>{withdrawals}</div>
    </article>
  );
};

export default BankWithdrawals;
