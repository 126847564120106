import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { languagesWithIcons } from 'services/locale';
import { actions as localeActions } from 'features/locale';
import Button from 'components/Button';
import PopUp from 'components/PopUp';

import './Locale.scss';

const b = block('locale');

const Locale = ({ forAuth, callback = () => null }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const history = useHistory();

  const [activeLang, changeActivelang] = useState(lang);

  const langItems = useMemo(
    () =>
      Object.entries(languagesWithIcons).map(temp => (
        <div
          key={temp[0]}
          className={b('item', { active: activeLang === temp[0] })}
          onClick={() => changeActivelang(temp[0])}>
          <img src={temp[1].icon} alt="" className={b('item-flag')} />
          <span className={b('item-text')}>{temp[1].text}</span>
        </div>
      )),
    [lang, dispatch, activeLang],
  );

  const handleButtonClick = () => {
    dispatch(localeActions.changeLang(activeLang));
    forAuth ? history.push('/') : history.goBack();
    callback();
  }

  const handleCloseClick = () => {
    forAuth ? history.push('/') : history.goBack();
    callback();
  }

  return (
    <PopUp
      title={locale.changeLang}
      onClose={handleCloseClick}
    >
      <section className={b()}>
        <section className={b('content')}>
          {langItems}
          <div className={b('bottom')}>
            <div className={b('button')}>
              <Button onClick={handleButtonClick}>{locale.changeLang}</Button>
            </div>
          </div>
        </section>
      </section>
    </PopUp>
  );
};

export default Locale;
