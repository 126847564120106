import { convertSlotsBetHistoryFilters } from 'features/slotsBetHistory/helpers/convertSlotsBetHistoryFilters';

import BaseApi from '../BaseApi';
import { SlotsBetHistoryConverter, MOCK } from './SlotsBetHistoryConverter';

class SlotsBetHistoryApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new SlotsBetHistoryConverter();
  }

  getSlotsBetHistory = args => {
    // return this.sendQueryMock(this.converter.convertSlotsBetHistory, MOCK);
    /* return this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/getSlotsBetHistory`,
      { ...args },
      null,
      this.converter.convertSlotsBetHistory,
    ); */

    return this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/bet_history`,
      { ...args },
      null,
      this.converter.convertSlotsBetHistory,
    );
  };
}

export default SlotsBetHistoryApi;
