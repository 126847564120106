export const getDifference = (arrOld, arrNew) => {
  const t1 = arrOld.map(t => t.id);
  const t2 = arrNew.map(t => t.id);

  const t3 = t2.filter(t => !t1.includes(t));
  return arrNew.filter(t => t3.includes(t.id));

  /* const t1 = [...arrOld, ...arrNew];
  const t2 = t1.map(t => t.id);
  const t3 = t1.filter((t, i, arr) => t2.filter(tt => tt === t.id).length === 1);
  return t3; */
};

// TEST
// console.log(getDifference([{ id: 1 }, { id: 2 }, { id: 3 }], [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]));
// console.log(
//   getDifference(
//     [
//       /* { id: 1 }, { id: 2 }, { id: 3 } */
//     ],
//     [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }],
//   ),
// );
// console.log(
//   getDifference([{ id: 1 }, { id: 2 }, { id: 3 }], [/* { id: 1 } */ { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }]),
// );
// console.log(getDifference([{ id: 1 }, { id: 2 }, { id: 3 }], [{ id: 1 }, { id: 2 }]));
