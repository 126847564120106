import error from './error.svg';
import alert from './alert.svg';
import shield from './shield.svg';

const notifyIcons = {
  error,
  success: shield,
  default: alert,
}

export const getNotifyIcon = type => notifyIcons[type] ?? notifyIcons.default;
