import { getDifference } from 'features/notifyPaymentPending/model';

import { initialState } from '../initial';
import { actionTypes } from '../actions';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADD_NOTIFY: {
      const diff = getDifference(state.notificationsOld, state.notificationsCur);
      const diff2 = getDifference(state.notificationsCur, action.payload);
      const diff3 = getDifference(state.notificationsOld, action.payload);
      const diff4 = getDifference(action.payload, state.notificationsCur);

      if (diff2.length > 0 && state.notifications.length === 0) {
        // console.log('init');
        return {
          ...state,
          notificationsCur: [...action.payload],
          notifications: [
            {
              key: JSON.stringify(diff2),
              count: diff2.length,
            },
          ],
          actual: action.payload,
          soundTrigger: {},
        };
      }
      // if (diff4.length > 0 && state.notifications.length > 0 && state.iteration === 1) {
      //   console.log('update cur -diff');
      //   return {
      //     ...state,
      //     notificationsCur: action.payload,
      //     notifications: [
      //       {
      //         key: state.notifications[0].key,
      //         count: diff3.length,
      //       },
      //     ],
      //     actual: action.payload
      //   };
      // }
      if (diff2.length > 0 && state.notifications.length > 0) {
        // console.log('update cur +diff');

        return {
          ...state,
          notificationsCur: action.payload,
          notifications: [
            {
              key: state.notifications[0].key,
              count: diff3.length,
            },
          ],
          actual: action.payload,
          soundTrigger: {},
        };
      }

      if (diff.length > 0 && state.notifications.length > 0) {
        // console.log('update view');
        return {
          ...state,
          notifications: [
            {
              key: state.notifications[0].key,
              count: diff.length,
            },
          ],
          actual: action.payload,
        };
      }
      // console.log('nothing');
      return { ...state, actual: action.payload };
    }

    case actionTypes.DELETE_NOTIFY: {
      const indexById = state.notifications.findIndex(x => x.key === action.payload);
      const notifications = [...state.notifications.slice(0, indexById), ...state.notifications.slice(indexById + 1)];

      return {
        ...state,
        notificationsOld: state.notificationsCur,
        notifications,
        // iteration: state.iteration + 1,
      };
    }

    case actionTypes.ADD_SOUND_NOTIFY: {
      return {
        ...state,
        soundTrigger: {},
      };
    }

    default:
      return state;
  }
}
