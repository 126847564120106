import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { formatNumberWithLimit } from 'shared/utils/formatNumber';
import Button from 'components/Button';

import './RequestCard.scss';

const b = block('request-card');

const RequestCard = ({ item, callback, withButton = false }) => {
  const locale = useSelector(state => state.locale.locale);

  const { id, date, amount, status, comment, paymentSystem, paymentRequisite,
    transactionId, bank, requisite, playerId } = item;

  return (
    <section className={b()} key={id}>
      <div className={b('item')}>
        <div className={b('name')}>ID</div>
        <div className={b('value')}>{id}</div>
      </div>
      <div className={b('item')}>
        <div className={b('name')}>{locale.amount}</div>
        <div className={b('value')}>{formatNumberWithLimit(amount)}</div>
      </div>
      <div className={b('item')}>
        <div className={b('name')}>{locale.paymentSystem}</div>
        <div className={b('value')}>{paymentSystem}</div>
      </div>
      <div className={b('item')}>
        <div className={b('name')}>{locale.paymentRequisite}</div>
        <div className={b('value')}>{paymentRequisite}</div>
      </div>
      {transactionId && (
        <div className={b('item')}>
          <div className={b('name')}>{locale.idOfTheTransaction}</div>
          <div className={b('value')}>{transactionId}</div>
        </div>
      )}
      <div className={b('item')}>
        <div className={b('name')}>{locale.status}</div>
        <div className={b('value', { status: String(status) })}>{locale.activeRequestsStatuses[status]}</div>
      </div>
      <div className={b('item')}>
        <div className={b('name')}>{locale.date}</div>
        <div className={b('value')}>{date}</div>
      </div>
      <div className={b('item')}>
        <div className={b('name')}>{locale.bank[1]}</div>
        <div className={b('value')}>{bank}</div>
      </div>
      <div className={b('item')}>
        <div className={b('name')}>{locale.requisite}</div>
        <div className={b('value')}>{requisite}</div>
      </div>
      <div className={b('item')}>
        <div className={b('name')}>{locale.playerId}</div>
        <div className={b('value')}>{playerId}</div>
      </div>
      {comment && (
        <div className={b('comment')}>
          <span className={b('name')}>{`${locale.comment}: `}</span>
          {comment}
        </div>
      )}
      {withButton && (
        <div className={b('button')}>
          <Button text={locale.change} callBack={callback} color="grey" />
        </div>
      )}
    </section>
  );
};

RequestCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    amount: PropTypes.number,
    status: PropTypes.number,
    comment: PropTypes.string,
    paymentSystem: PropTypes.string,
    paymentRequisite: PropTypes.string,
    transactionId: PropTypes.string,
  }).isRequired,
  callback: PropTypes.func,
  withButton: PropTypes.bool,
};

export default RequestCard;
