import React, { useCallback } from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';

import './Button.scss';

const b = block('button');

const Button = ({
  children,
  disabled = false,
  color = 'default',
  padding = 'default',
  borderRadius = 'default',
  type = 'button',
  link,
  onClick,
  ...restProps
}) => {
  const handleClick = useCallback(
    e => {
      if (disabled) {
        e.preventDefault();
      } else if (onClick) {
        onClick(e);
      }
    },
    [disabled, onClick],
  );

  return link ? (
    <Link className={b({ disabled, color, borderRadius, padding })} to={link} onClick={handleClick} {...restProps}>
      {children}
    </Link>
  ) : (
    <button type={type} className={b({ disabled, color, borderRadius, padding })} onClick={handleClick} {...restProps}>
      {children}
    </button>
  );
};

export default Button;
