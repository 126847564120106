import React, { useMemo } from 'react';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import InputDate from 'components/InputDate';

import Button from 'components/Button';
import Select from 'components/Select';
import Input from 'components/Input/desktop';

import './TopUpFilter.scss';

const b = block('top-up-filter-desktop');

const TopUpFilter = ({ filterData }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const { state, write, statusItems, onSubmit, banks } = filterData;

  return (
    <form className={b()} onSubmit={onSubmit}>
      <div className={b('items')}>
      <div className={b('item')}>
          <span className={b('label')}>{locale.from}</span>
          <div className={b('item-dates-input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(state.from)}
              onChange={value => write(value, 'from')}
            />
          </div>
        </div>

        <div className={b('item')}>
          <span className={b('label')}>{locale.to}</span>
          <div className={b('item-dates-input')}>
            <InputDate dateFormat="yyyy-MM-dd" selected={new Date(state.to)} onChange={value => write(value, 'to')} />
          </div>
        </div>

        <div className={b('item')}>
          <span className={b('label')}>ID</span>
          <div className={b('item-double-input')}>
            <Input color="dark-green" value={state.id} onChange={e => write(e, 'id')} type="number" placeholder="ID" />
          </div>
        </div>

        <div className={b('item')}>
          <span className={b('label')}>{locale.bank[1]}</span>
          <div className={b('item-double-input')}>
            {banks.length > 0 && <Select
              items={banks}
              activeItem={banks.find(p => p.value === state.bank) || banks[0]}
              placeholder={locale.bank[1]}
              onChange={value => write(value, 'bank')}
            />}
          </div>
        </div>

        <div className={b('item')}>
          <span className={b('label')}>{locale.amountFrom}</span>
          <div className={b('item-double-input')}>
            <Input
              color="dark-green"
              value={state.amountFrom}
              onChange={e => write(e, 'amountFrom')}
              type="number"
              placeholder={locale.amountFrom}
            />
          </div>
        </div>

        <div className={b('item')}>
          <span className={b('label')}>{locale.amountTo}</span>
          <div className={b('item-double-input')}>
            <Input
              color="dark-green"
              value={state.amountTo}
              onChange={e => write(e, 'amountTo')}
              type="number"
              placeholder={locale.amountTo}
            />
          </div>
        </div>
        
        <div className={b('button')}>
          <Button type="submit">{locale.showResults}</Button>
        </div>
      </div>
    </form>
  );
};

TopUpFilter.propTypes = {};

export default TopUpFilter;
