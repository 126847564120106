import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';
import { useHistory } from 'react-router-dom';

import { actions as userActions } from 'features/users/redux';
import Input from 'components/Input/desktop';
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import PopUp from 'components/PopUp';

import './ChangePas.scss';

const b = block('change-pas');

const ChangePas = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id } = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { actionProcessing } = useSelector(state => state.users, shallowEqual);

  const [oldPassword, editOldPassword] = useState('');
  const [newPassword, editNewPassword] = useState('');
  const [repeatNewPassword, editRepeatNewPassword] = useState('');
  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      dispatch(userActions.changePassword({ id, oldPassword, newPassword, repeatNewPassword }));
    },
    [id, newPassword, oldPassword, repeatNewPassword],
  );

  return (
    <PopUp
      title={locale.changePassword}
      onClose={() => history.goBack()}
    >
      <section className={b()}>
        <Spinner isLoading={actionProcessing} />
        <section className={b('content')}>
          <div className={b('main')}>
            <form className={b('form')} onSubmit={onSubmit}>
              <label className={b('item')}>
                <p className={b('item-title')}>{locale.oldPassword}</p>
                <div className={b('item-field')}>
                  <Input
                    value={oldPassword}
                    name="old-password"
                    placeholder={locale.oldPassword}
                    type="password"
                    onChange={e => editOldPassword(e.currentTarget.value)}
                  />
                </div>
              </label>

              <label className={b('item')}>
                <p className={b('item-title')}>{locale.newPassword}</p>
                <div className={b('item-field')}>
                  <Input
                    value={newPassword}
                    name="new-password"
                    placeholder={locale.newPassword}
                    type="password"
                    onChange={e => editNewPassword(e.currentTarget.value)}
                  />
                </div>
              </label>

              <label className={b('item')}>
                <p className={b('item-title')}>{locale.repeatNewPassword}</p>
                <div className={b('item-field')}>
                  <Input
                    value={repeatNewPassword}
                    name="repeat-new-password"
                    placeholder={locale.repeatNewPassword}
                    type="password"
                    onChange={e => editRepeatNewPassword(e.currentTarget.value)}
                  />
                </div>
              </label>

              <div className={b('button')}>
                <Button type="submit">{locale.changePassword}</Button>
              </div>
            </form>
          </div>
        </section>
      </section>
    </PopUp>
  );
};

export default ChangePas;
