import React from 'react';
import block from 'bem-cn';

import SVG from 'components/SVG';

import crossSVG from './img/cross.svg';

import './PopUp.scss';

const b = block('pop-up');

const PopUp = ({ title, onClose, children }) => {
  return (
    <div className={b()}>
      <div className={b('content')}>
        <div className={b('header')}>
          {title}
          <div className={b('cross-container')} onClick={onClose}>
            <SVG svgClassName={b('cross')} svgProps={{ svg: crossSVG }} />
          </div>
        </div>
        <div className={b('body')}>{children}</div>
      </div>
    </div>
  );
};

export default PopUp;
