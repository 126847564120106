import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import notifyMp3 from './data/notify.mp3';

const SoundNotification = () => {
  const trigger = useSelector(state => state.notifyPaymentPending.soundTrigger);

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (!isFirstLoad) new Audio(notifyMp3).play();
    setIsFirstLoad(false);
  }, [trigger]);

  return null;
}

export default SoundNotification;