import React, { useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import Input from 'components/Input/desktop';
import Button from 'components/Button';
import { bankLogos } from 'features/PaymentAdmin/img';
import { bankNames } from 'features/PaymentAdmin/data';

import { actions } from 'features/PaymentAdmin/redux';
import './ChangingForm.scss';

const b = block('changing-form');

const ChangingForm = () => {
  const selectedRequisite = useSelector(state => state.paymentAdmin.selectedRequisite);
  const locale = useSelector(state => state.locale.locale);
  const history = useHistory();
  const dispatch = useDispatch();

  const [newRequisite, setNewRequisite] = useState(selectedRequisite?.requisite);

  const handleInputChange = e => setNewRequisite(e.currentTarget.value);

  const handleFormSubmit = e => {
    e.preventDefault();
    dispatch(
      actions.removeRequisite({
        id: selectedRequisite.id,
        callback: () => history.replace('/requisites'),
      }),
    );
  };
  
  if (!selectedRequisite) return <Redirect to="/requisites" />;

  return (
    <form className={b()} onSubmit={handleFormSubmit}>
      <img className={b('logo')} src={bankLogos[selectedRequisite.bank]} alt="" />
      <div className={b('bank')}>{bankNames[selectedRequisite.bank] ?? selectedRequisite.bank}</div>
      <div className={b('input-block')}>
        <div className={b('name')}>ID:</div>
        <div className={b('input-field')}>
          <div className={b('id')}>{selectedRequisite.id}</div>
        </div>
      </div>
      <div className={b('input-block')}>
        <div className={b('name')}>{`${locale.requisite}:`}</div>
        <div className={b('input-field')}>
          <Input
            value={newRequisite}
            onChange={handleInputChange}
            color="dark-green"
            scheme="requisites"
            placeholder={locale.cardNumber}
            disabled
          />
        </div>
      </div>
      <div className={b('button')}>
        <Button type="submit">{locale.removeRequisite}</Button>
      </div>
    </form>
  );
};

ChangingForm.propTypes = {};

export default ChangingForm;
