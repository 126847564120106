import { initialState } from "./initial";
import { actionTypes } from "./actions";

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSFERS:
      const { transfers, count } = action.payload
      return { ...state, transfers, count};
    default:
      return { ...state };
  }
};