import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import SVG from 'components/SVG';
import { NotifyModel } from 'services/models/NotifyModel';

import { getNotifyIcon } from '../../img';
import crossSVG from '../../img/cross.svg';

import './Notification.scss';

const b = block('notification-payment-desktop');

class Notification extends React.PureComponent {
  constructor(props) {
    super(props);
    this.notifyRef = React.createRef();
    this.state = {
      isDeleting: false,
      isDeleted: false,
    };
  }

  componentDidMount() {
    const { needClose } = this.props.item;
    const touchListener = new NotifyModel(() => this._hideNotify(), this.notifyRef.current);
    touchListener.init();
    /* if (needClose) {
      setTimeout(this._hideNotify, 7000);
    } */
  }

  render() {
    const {
      item: { count },
      locale,
    } = this.props;
    return (
      <div className={b({ deleting: this.state.isDeleting })} ref={this.notifyRef}>
        <div className={b('text')}>
          <span className={b('text1')}>{locale.newPaymentsPending}:</span>
          <span className={b('text2')}>{count}</span>
        </div>

        <SVG className={b('cross')} svgProps={{ svg: crossSVG }} onClick={() => this._hideNotify()} />
      </div>
    );
    // const {
    //   item: { username, paymentType },
    //   locale,
    // } = this.props;
    // return (
    //   <div className={b({ deleting: this.state.isDeleting })} ref={this.notifyRef}>
    //     <div className={b('text')}>
    //       <span className={b('text1')}>{paymentType === 0 ? locale.newTopUp : locale.newWithdrawal}:</span>
    //       <span className={b('text2')}>{username}</span>
    //     </div>

    //     <SVG className={b('cross')} svgProps={{ svg: crossSVG }} onClick={() => this._hideNotify()} />
    //   </div>
    // );
  }

  _hideNotify = () => {
    const {
      deleteNotify,
      item: { key },
    } = this.props;
    if (!this.state.isDeleted) {
      this.setState({ isDeleting: true }, () =>
        setTimeout(() => {
          this.setState({ isDeleted: true });
          deleteNotify(key);
        }, 700),
      );
    }
  };
}

export default Notification;
