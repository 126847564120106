import React, { useState, useCallback, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';

import { actions as totalActions } from 'features/Total/redux';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import Button from 'components/Button';
import InputDateNative from 'components/InputDateNative/desktop';

import './Total.scss';

const b = block('total');

const Total = () => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);
  const totalInfo = useSelector(state => state.total.totalInfo);
  const name = useSelector(state => state.auth.name, shallowEqual);
  const role = useSelector(state => state.auth.role, shallowEqual);
  const userList = useSelector(state => state.total.users, shallowEqual);
  const actionProcessing = useSelector(state => state.total.actionProcessing);

  const formattedCashierTransactions = {
    ...totalInfo,
    total: formatNumberWithLimit(totalInfo.total),
    credits: formatNumberWithLimit(totalInfo.credits),
    debits: formatNumberWithLimit(totalInfo.debits),
  };

  const [fromDate, changeFromDate] = useState(dayjs(new Date()).add(-3, 'day').format('YYYY-MM-DDTHH:mm:ss'));
  const [toDate, changeToDate] = useState(dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'));

  useEffect(() => {
    dispatch(totalActions.getTotal(fromDate, toDate));
  }, [dispatch]);

  const callBack = useCallback(() => dispatch(totalActions.getTotal(fromDate, toDate)), [fromDate, toDate, dispatch]);

  const onUserClick = useCallback(
    id => {
      if (!actionProcessing) {
        dispatch(totalActions.getUserData(id, fromDate, toDate));
      }
    },
    [actionProcessing, dispatch, fromDate, toDate],
  );

  const cashier = useMemo(() => {
    return (
      <div key={name} className={b('row')}>
        <span className={b('td')}>{role === 1 ? name : locale.total}</span>
        <span className={b('td', { accented: true })}>{formattedCashierTransactions.total}</span>
        <span className={b('td', { accented: true })}>{formattedCashierTransactions.credits}</span>
        <span className={b('td', { accented: true })}>{formattedCashierTransactions.debits}</span>
      </div>
    );
  }, [role, totalInfo, name]);

  const users = useMemo(
    () =>
      userList.length === 1 && !!!userList[0].userId
        ? null
        : userList.map(t => (
            <div key={t.userId} className={b('row')} onClick={() => onUserClick(t.userId)}>
              <span className={b('td')}>{t.username}</span>
              <span className={b('td')}>{formatNumberWithLimit(t.total)}</span>
              <span className={b('td')}>{formatNumberWithLimit(t.credits)}</span>
              <span className={b('td')}>{formatNumberWithLimit(t.debits)}</span>
            </div>
          )),
    [onUserClick, userList],
  );

  return (
    <section className={b()}>
      <h3 className={b('title')}>{locale['/total']}</h3>
      <div className={b('content')}>
        <div className={b('fields')}>
          <div className={b('field-title')}>{`${locale.from}`}</div>
          <div className={b('field-title')}>{`${locale.to}`}</div>
          <div />
          <div className={b('field-input')}>
            <InputDateNative value={fromDate} onChange={changeFromDate} />
          </div>
          <div className={b('field-input')}>
            <InputDateNative value={toDate} onChange={changeToDate} />
          </div>
          <div className={b('button')}>
            <Button onClick={actionProcessing ? f => f : callBack}>{locale.showResults}</Button>
          </div>
        </div>
      </div>
      <article className={b('result')}>
        <div className={b('result-header')}>
          <span className={b('th')}>{locale.nickname}</span>
          <span className={b('th')}>{locale.total}</span>
          <span className={b('th')}>{locale.deposit}</span>
          <span className={b('th')}>{locale.withdraw}</span>
        </div>
        {users}
        {cashier}
      </article>
    </section>
  );
};

export default Total;
