import React from 'react';
import block from 'bem-cn';
import { useHistory, withRouter } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';

import SVG from 'components/SVG';

import backArrow from './img/arrow-back.svg';

import './BackBlock.scss';

const b = block('back-block');

const BackBlock = ({ children, route, needBottomBorder = false }) => {
  const history = useHistory();

  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const pushRoute = () => (route ? history.push(route) : history.goBack());

  return (
    <button className={b({ needBottomBorder })} onClick={() => pushRoute()}>
      <div className={b('arrow-container')}>
        <SVG svgClassName={b('arrow')} svgProps={{ svg: backArrow }} />
      </div>
      <div className={b('content')}>{children || locale.back}</div>
    </button>
  );
};

export default withRouter(BackBlock);
