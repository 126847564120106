import dayjs from 'dayjs';
import { addNotify } from 'features/notify';

const actionTypes = {
  ACTION_PROCESSING: 'withdrawal/ACTION_PROCESSING',
  GET_WITHDRAWAL_REQUESTS_SUCCESS: 'withdrawal/GET_SLOTS_BET_HISTORY_SUCCESS',
};

function getWithdrawalRequests(info) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const locale = getState().locale.locale;
    const response = await api.withdrawal.getWithdrawalRequests(info);

    if (response.success) {
      dispatch({ type: actionTypes.GET_WITHDRAWAL_REQUESTS_SUCCESS, payload: response.data });
    } else {
      const errorMessage = locale[response.codeStatus] ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

function resolveCard(info, callback) {
  return async (dispatch, getState, extra) => {
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: true });
    const { api } = extra;
    const locale = getState().locale.locale;
    const response = await api.withdrawal.resolveCard(info);

    if (response.success) {
      dispatch(addNotify(locale.defaultSuccess, 'success'));
    } else {
      const errorMessage = locale[response.codeStatus] ?? locale.defaultMessage;
      dispatch(addNotify(errorMessage, 'error'));
    }

    if (callback) callback();
    dispatch({ type: actionTypes.ACTION_PROCESSING, payload: false });
  };
}

export { actionTypes, getWithdrawalRequests, resolveCard };
