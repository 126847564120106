// import russia from './img/russia.png';
import turkmenistan from './img/turkmenistan.png';
// import kazakh from './img/kazakhstan.png';
// import kyrgyzstan from './img/kyrgyzstan.png';
// import ukraine from './img/ukraine.png';
// import uzbekistan from './img/uzbekistan.png';
// import belarus from './img/belarus.png';
// import turkey from './img/turkey.png';
// import Tunisia from './img/Tunisia.png';
import Azerbaijan from './img/azerbaijan.png';
import Peru from './img/peru.png';

export const phoneCodes = {
  // RUSSIAN: {
  //   code: '+7',
  //   img: russia,
  // },
  // KAZAKH: {
  //  code: '+7',
  //  img: kazakh,
  // },
  // TUNIS: {
  //  code: '+216',
  //  img: Tunisia,
  // },
  // TURKMEN: {
  //   code: '993',
  //   img: turkmenistan,
  // },
  // AZERBAIJAN: {
  //   code: '994',
  //   img: Azerbaijan,
  // },
  // KYRGYSTAN: {
  //   code: '+996',
  //   img: kyrgyzstan,
  // },
  // BELARUS: {
  //   code: '+375',
  //   img: belarus,
  // },
  // UKRAINE: {
  //   code: '+380',
  //   img: ukraine,
  // },
  // UZBEK: {
  //   code: '+998',
  //   img: uzbekistan,
  // },
  // TURKEY: {
  //  code: '+90',
  //  img: turkey,
  //  appropriateCurrency: 'TRY',
  // },
  PERU: {
    code: '51',
    img: Peru,
  },
};
