import React, { useEffect, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import { actions as paymentAdminActions } from 'features/PaymentAdmin';

import './BankWithdrawalsItem.scss';
import RedactModal from './RedactModal';
import { useState } from 'react';

const b = block('bank-withdrawals-item');

const BankWithdrawalsItem = ({ item }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);

  const [isModalOpened, setIsModalOpened] = useState(false);

  // id: 2,
  // amount: 22,
  // bank: 'test_bank2',
  // requisite: 'test_requisite2',
  // player_id: 222,
  // status: 1,
  // phone: 2234567890,
  // create_date: '02-01-2022T00:00',

  return (
    <div className={b()}>
      <div className={b('top')}>
        <div className={b('data')}>
          <div className={b('title')}>ID</div>
          <div className={b('value')}>{item.id}</div>
        </div>
        <div className={b('data')}>
          <div className={b('title')}>{locale.amount}</div>
          <div className={b('value')}>{item.amount}</div>
        </div>
        <div className={b('data')}>
          <div className={b('title')}>{locale.bank}</div>
          <div className={b('value')}>{item.bank}</div>
        </div>
        <div className={b('data')}>
          <div className={b('title')}>{locale.requisite}</div>
          <div className={b('value')}>{item.requisite}</div>
        </div>
        <div className={b('data')}>
          <div className={b('title')}>{locale.player_id}</div>
          <div className={b('value')}>{item.player_id}</div>
        </div>
        <div className={b('data')}>
          <div className={b('title')}>{locale.status}</div>
          <div className={b('value')}>{locale.bankWithdrawalStatuses[item.status]}</div>
        </div>
        <div className={b('data')}>
          <div className={b('title')}>{locale.phone}</div>
          <div className={b('value')}>{item.phone}</div>
        </div>
        <div className={b('data')}>
          <div className={b('title')}>{locale.create_date}</div>
          <div className={b('value')}>{item.create_date}</div>
        </div>
      </div>
      {item.status === 0 && (
        <div className={b('button')} onClick={() => setIsModalOpened(true)}>
          {locale.redact}
        </div>
      )}

      {isModalOpened && <RedactModal item={item} closeFunction={() => setIsModalOpened(false)} />}
    </div>
  );
};

export default BankWithdrawalsItem;
