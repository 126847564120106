import React, { useState } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Input from 'components/Input/desktop';
import Button from 'components/Button';
import { actions as notifyActions } from 'features/notify';

import { actions } from 'features/PaymentAdmin/redux';
import './DetailCard.scss';

const b = block('detail-card');

const DetailCard = ({ item, callback }) => {
  const { id, date, amount, status, comment, paymentSystem, paymentRequisite, imageUrl, transactionId } = item;
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    amount,
    comment,
  });

  const handleInputChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleRequestSend = status => {
    if (!status) return;
    if (status === 2 && !state.comment) {
      dispatch(notifyActions.addNotify(locale.addComment, 'error'));
      return;
    }

    dispatch(
      actions.setRequest({
        id,
        status,
        comment: state.comment,
        amount: Number(state.amount),
        callback: callback,
      }),
    );
  };

  return (
    <section className={b()} key={id}>
      <h3 className={b('title')}>{`ID: ${id}`}</h3>
      <div className={b('content')}>
        <div className={b('item')}>
          <div className={b('name')}>{locale.paymentSystem}</div>
          <div className={b('value')}>{paymentSystem}</div>
        </div>
        <div className={b('item')}>
          <div className={b('name')}>{locale.requisite}</div>
          <div className={b('value')}>{paymentRequisite}</div>
        </div>
        <div className={b('item')}>
          <div className={b('name')}>{locale.amount}</div>
          <div className={b('value')}>{amount}</div>
        </div>
        <div className={b('item')}>
          <div className={b('name')}>{locale.date}</div>
          <div className={b('value')}>{date}</div>
        </div>
        <div className={b('item')}>
          <div className={b('name')}>ID</div>
          <div className={b('value')}>{id}</div>
        </div>
        <div className={b('item')}>
          <div className={b('name')}>{locale.status}</div>
          <div className={b('value', { status: String(status) })}>{locale.activeRequestsStatuses[status]}</div>
        </div>
        {transactionId && (
          <div className={b('item')}>
            <div className={b('name')}>{locale.idOfTheTransaction}</div>
            <div className={b('value')}>{transactionId}</div>
          </div>
        )}
        {comment && (
          <div className={b('comment')}>
            <span className={b('name')}>{`${locale.comment}: `}</span>
            {comment}
          </div>
        )}
      </div>
      <img className={b('image')} src={imageUrl} alt="" />
      <div className={b('input-block')}>
        <div className={b('name')}>{`${locale.amount}:`}</div>
        <div className={b('input-field')}>
          <Input type="number" name="amount" value={state.amount} onChange={handleInputChange} />
        </div>
      </div>
      <div className={b('input-block')}>
        <div className={b('name')}>{`${locale.comment}:`}</div>
        <div className={b('input-field')}>
          <Input name="comment" value={state.comment} onChange={handleInputChange} />
        </div>
      </div>
      <div className={b('buttons')}>
        <Button text={locale.deposit} callBack={() => handleRequestSend(1)} />
        <Button text={locale.reject} color="red" callBack={() => handleRequestSend(2)} />
        <Button text={locale.refunded} callBack={() => handleRequestSend(3)} />
        <Button text={locale.error} color="red" callBack={() => handleRequestSend(4)} />
      </div>
    </section>
  );
};

DetailCard.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    date: PropTypes.string,
    amount: PropTypes.number,
    status: PropTypes.number,
    comment: PropTypes.string,
    paymentSystem: PropTypes.string,
    paymentRequisite: PropTypes.string,
    transactionId: PropTypes.string,
    imgUrl: PropTypes.string,
  }).isRequired,
  callback: PropTypes.func,
};

export default DetailCard;
