import BaseApi from '../BaseApi';
import { PaymentAdminConverter } from './PaymentAdminConverter';

class PaymentAdminApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.apiUrl = `${baseUrl}/api`;
    this.converter = new PaymentAdminConverter();
  }

  getRequisites = () => this.sendQuery(this.queryTypes.GET, `${this.baseUrl}/admin_requisite`);

  setRequisite = ({ id, requisite }) =>
    this.sendQuery(this.queryTypes.PUT, `${this.baseUrl}/admin_requisite`, {
      requisite_id: id,
      payment_requisite: requisite,
    });

  removeRequisite = ({ id }) =>
    this.sendQuery(this.queryTypes.DELETE, `${this.baseUrl}/admin_requisite`, {
      requisite_id: id,
    });

  getBanks = () =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.apiUrl}/site/admin_payment_systems`,
      null,
      null,
      this.converter.convertBanks,
    );

  createRequisite = ({ bank, requisite }) =>
    this.sendQuery(this.queryTypes.POST, `${this.baseUrl}/admin_requisite`, {
      payment_system: bank,
      payment_requisite: requisite,
    });

  getActiveRequests = ({ count, page, transactionId }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/admin_deposit`,
      { count, page, id_of_the_transaction: transactionId },
      null,
      this.converter.convertActiveRequests,
    );

  setRequest = ({ id, status, comment, amount }) =>
    this.sendQuery(this.queryTypes.PATCH, `${this.baseUrl}/admin_deposit`, { id, status, comment, amount });

  getRequestsHistory = ({ from, to, count, page, status }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/admin_deposit/history`,
      { count, page, date_from: from, date_to: to, status },
      null,
      this.converter.convertRequestsHistory,
    );

  getWithdrawalRequestsHistory = ({ from, to, count, page, status }) =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/cashier_withdrawal_by_payment/history`,
      { count, page, date_from: from, date_to: to, status },
      null,
      this.converter.convertWithdrawalRequestsHistory,
    );

  getBankWithdrawals = ({ status }) => {
    const params = status !== -1 ? new URLSearchParams({ status }) : null;

    return this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/cashier_withdrawal_by_payment`,
      params,
      null,
      this.converter.convertBankWithdrawals,
    );
  };

  changeBankWithdrawalStatus = ({ id, status, transactionId }) => {
    return this.sendQuery(
      this.queryTypes.PATCH,
      `${this.baseUrl}/cashier_withdrawal_by_payment`,
      { id, status, id_of_the_transaction: transactionId },
      null,
    );
  };
}

export default PaymentAdminApi;
