import BaseApi from "../BaseApi";
import { TotalBalanceConverter } from "./TotalBalanceConverter";

class TotlApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new TotalBalanceConverter();
  }

  getTotal = (fromDate, toDate) =>
    this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/transfers`,
      { from_date: fromDate, to_date: toDate },
      null,
      this.converter.totalConverter
    );
}

export default TotlApi;
