import React, { useCallback, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import { actions as paymentAdminActions } from 'features/PaymentAdmin';
import { addNotify } from 'features/notify';

import PopUp from 'components/PopUp';
import Input from 'components/Input/desktop';

import './RedactModal.scss';

const b = block('redact-modal');

const RedactModal = ({ item, closeFunction }) => {
  const dispatch = useDispatch();

  const locale = useSelector(state => state.locale.locale);

  const [transactionId, setTransactionId] = useState(0);

  const handleClose = useCallback(() => {
    closeFunction();
  }, [closeFunction]);

  const handleApprove = useCallback(() => {
    if (transactionId !== '') {
      dispatch(
        paymentAdminActions.changeBankWithdrawalStatus({
          id: item.id,
          status: 1,
          transactionId: parseInt(transactionId),
        }),
      );
      closeFunction();
    } else {
      dispatch(addNotify(locale.transactionIdRequired, 'error'));
    }
  }, [item, dispatch, closeFunction, transactionId]);

  const handleCancel = useCallback(() => {
    if (transactionId !== '') {
      dispatch(
        paymentAdminActions.changeBankWithdrawalStatus({
          id: item.id,
          status: 2,
          transactionId: parseInt(transactionId),
        }),
      );
      closeFunction();
    } else {
      dispatch(addNotify(locale.transactionIdRequired, 'error'));
    }
  }, [item, dispatch, closeFunction, transactionId]);

  return (
    <PopUp onClose={closeFunction}>
      <div className={b()}>
        <div className={b('top')}>
          <div className={b('data')}>
            <div className={b('title')}>ID</div>
            <div className={b('value')}>{item.id}</div>
          </div>
          <div className={b('data')}>
            <div className={b('title')}>{locale.amount}</div>
            <div className={b('value')}>{item.amount}</div>
          </div>
          <div className={b('data')}>
            <div className={b('title')}>{locale.bank}</div>
            <div className={b('value')}>{item.bank}</div>
          </div>
          <div className={b('data')}>
            <div className={b('title')}>{locale.requisite}</div>
            <div className={b('value')}>{item.requisite}</div>
          </div>
          <div className={b('data')}>
            <div className={b('title')}>{locale.player_id}</div>
            <div className={b('value')}>{item.player_id}</div>
          </div>
          <div className={b('data')}>
            <div className={b('title')}>{locale.status}</div>
            <div className={b('value')}>{locale.bankWithdrawalStatuses[item.status]}</div>
          </div>
          <div className={b('data')}>
            <div className={b('title')}>{locale.phone}</div>
            <div className={b('value')}>{item.phone}</div>
          </div>
          <div className={b('data')}>
            <div className={b('title')}>{locale.create_date}</div>
            <div className={b('value')}>{item.create_date}</div>
          </div>
        </div>

        <div className={b('button1')} onClick={handleClose}>
          {locale.close}
        </div>
        <div className={b('button2')} onClick={handleCancel}>
          {locale.cancel}
        </div>
        <Input
          value={transactionId}
          onChange={e => setTransactionId(e.currentTarget.value)}
          style={{ marginTop: '1.25rem' }}
          placeholder={locale.idOfTheTransaction}
        />
        <div className={b('button3')} onClick={handleApprove}>
          {locale.approve}
        </div>
      </div>
    </PopUp>
  );
};

export default RedactModal;
