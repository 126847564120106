const actionTypes = {
  ADD_NOTIFY: 'notifyPaymentPending/ADD_NOTIFY',
  DELETE_NOTIFY: 'notifyPaymentPending/DELETE_NOTIFY',
  ADD_SOUND_NOTIFY: 'notifyPaymentPending/ADD_SOUND_NOTIFY',
};

const getNotifyPaymentPending = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const response = await api.notifyPaymentPending.getNotifyPaymentPending();
  if (response.success) {
    dispatch(addNotify(response.data));
  }
};

function addNotify(list) {
  return async dispatch => {
    dispatch({ type: actionTypes.ADD_NOTIFY, payload: list });
  };
}

function deleteNotify(key) {
  return async dispatch => {
    dispatch({ type: actionTypes.DELETE_NOTIFY, payload: key });
  };
}

function addSoundNotify() {
  return async dispatch => {
    dispatch({ type: actionTypes.ADD_SOUND_NOTIFY });
  };
}

export { actionTypes, addNotify, deleteNotify, getNotifyPaymentPending, addSoundNotify };
