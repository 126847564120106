import React, { useCallback, useEffect, useMemo, useState } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { actions as topUpActions } from 'features/topUp';
import { actions as paymentAdminActions } from 'features/PaymentAdmin/redux';

import { statusItems, itemsOnPage } from './data';

const useTopUpRequests = () => {
  const dispatch = useDispatch();
  const { history, pages } = useSelector(state => state.topUp, shallowEqual);
  const banksRequisites = useSelector(state => state.paymentAdmin.banksRequisites);
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const banks = useMemo(() => {
    const items = Object.keys(banksRequisites).map(item => ({ value: item, name: item }));
    return [ { value: '', name: locale.all }, ... items]
  }, [banksRequisites]);

  useEffect(() => {
    dispatch(paymentAdminActions.getRequisites());
  }, [dispatch]);

  const [state, setState] = useState({
    from: dayjs()
      .add(-1, 'month')
      .format('YYYY-MM-DD'),
    to: dayjs().format('YYYY-MM-DD'),
    id: '',
    amountFrom: '',
    amountTo: '',
    bank: null,
    page: 0,
  });

  const { id, amountTo, amountFrom, statuses, from, to, page, bank } = state;

  const write = useCallback(
    (value, type) => {
      const setter = {
        id: t => setState({ ...state, id: t.target.value }),
        amountFrom: t => setState({ ...state, amountFrom: t.target.value }),
        amountTo: t => setState({ ...state, amountTo: t.target.value }),
        bank: t => setState({ ...state, bank: t }),
        from: t => setState({ ...state, from: dayjs(t).format('YYYY-MM-DD') }),
        to: t => setState({ ...state, to: dayjs(t).format('YYYY-MM-DD') }),
        page: t => setState({ ...state, page: t }),
      };

      setter[type](value);
    },
    [state],
  );

  const onSubmit = useCallback(
    e => {
      e?.preventDefault();
      dispatch(
        topUpActions.getTopUpRequests({
          id,
          bank_name: bank ? bank === '' ? null : bank : null,
          id,
          amount_from: amountFrom,
          amount_to: amountTo,
          count: itemsOnPage,
          page,
          date_from: dayjs(from).format('YYYY-MM-DD'),
          date_to: dayjs(to)
            .add(1, 'day')
            .format('YYYY-MM-DD'),
        }),
      );
    },
    [from, to, id, bank, statuses, page, amountFrom, amountTo, dispatch],
  );

  // если айтэмы приходят под конкретную страницу и число страниц определяется бэком
  const pageCount = useMemo(() => pages, [pages]);

  const items = useMemo(
    () =>
      history.map((t, index) => ({
        ...t,
        key: `${Object.values(t).join('-')}_${index}_${page}`,
      })),
    [history, page],
  );

  // если айтэмы приходят для всех страниц
  // const pageCount = useMemo(() => Math.ceil(history.length / itemsOnPage), [history]);
  // const items = useMemo(
  //   () =>
  //     history.slice(page * itemsOnPage, page * itemsOnPage + itemsOnPage).map((t, index) => ({
  //       ...t,
  //       key: `${Object.values(t).join('-')}_${index}_${page}`,
  //     })),
  //   [history, page],
  // );

  useEffect(() => {
    dispatch(
      topUpActions.getTopUpRequests({
        bank_name: bank === null ? bank === '' ? null : bank : null,
        id,
        amount_from: state.amountFrom,
        amount_to: state.amountTo,
        count: itemsOnPage,
        page,
      }),
    );
  }, [page]);

  return useMemo(
    () => ({
      date_from: dayjs(from).format('YYYY-MM-DD'),
      date_to: dayjs(to)
        .add(1, 'day')
        .format('YYYY-MM-DD'),
      items,
      pageCount,
      state,
      statusItems,
      banks,
      write,
      onSubmit,
    }),
    [items, pageCount, state, banks, write, onSubmit],
  );
};

export default useTopUpRequests;
