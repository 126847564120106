import React, { useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import { phoneCodes } from '../data';

import './CountryCodeSelector.scss';

const b = block('country-code-selector');

const CountryCodeSelector = ({ activeValue, callBack }) => {
  const [isOpen, setOpen] = useState(false);

  const list = useMemo(
    () =>
      Object.keys(phoneCodes).map(
        key =>
          activeValue !== key && (
            <div key={key} className={b('item')} onClick={() => callBack(key)}>
              <img className={b('image')} src={phoneCodes[key]?.img} alt="" />+{phoneCodes[key]?.code}
            </div>
          ),
      ),
    [activeValue, callBack],
  );

  return (
    <div className={b({ open: isOpen })} onClick={() => setOpen(!isOpen)}>
      <div className={b('active-item', { opened: isOpen ? 'open' : 'close' })}>
        <img className={b('image')} src={phoneCodes[activeValue]?.img} alt="" />+{phoneCodes[activeValue]?.code}
      </div>
      <div className={b('additional-items')}>{isOpen && list}</div>
    </div>
  );
};

CountryCodeSelector.propTypes = {
  activeValue: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired,
};

export default CountryCodeSelector;
