import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import Input from 'components/Input/desktop';
import Button from 'components/Button';
import Select from 'components/Select';
import { actions as notifyActions } from 'features/notify';

import { bankLogos } from 'features/PaymentAdmin/img';
import { bankNames } from 'features/PaymentAdmin/data';

import { actions } from 'features/PaymentAdmin/redux';
import './CreateRequisite.scss';

const b = block('create-requisite');

const CreateRequisite = () => {
  const banks = useSelector(state => state.paymentAdmin.banks);
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getBanks());
  }, []);

  const [bank, setBank] = useState({ name: locale.chooseBank, value: '' });
  const [requisite, setRequisite] = useState('');

  const bankItems = banks.map(bank => ({
    name: bankNames[bank] ?? bank,
    value: bank,
    image: bankLogos[bank],
  }));

  const handleInputChange = e => setRequisite(e.currentTarget.value);
  const handleSelectBank = value => {
    setBank(bankItems.find(item => item.value === value) ?? bankItems[0]);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    if (!bank.value) {
      dispatch(notifyActions.addNotify(locale.chooseBank, 'error'));
      return;
    }

    dispatch(
      actions.createRequisite({
        requisite,
        bank: bank.value,
        callback: () => setRequisite(''),
      }),
    );
  };

  return (
    <form className={b()} onSubmit={handleFormSubmit}>
      <div className={b('title')}>{locale.createRequisite}</div>
      <div className={b('block')}>
        <div className={b('input-field')}>
          <Select items={bankItems} activeItem={bank} onChange={handleSelectBank} />
        </div>
      </div>
      <div className={b('block')}>
        <div className={b('name')}>{`${locale.requisite}:`}</div>
        <div className={b('input-field')}>
          <Input
            value={requisite}
            color="dark-green"
            onChange={handleInputChange}
            scheme="requisites"
            placeholder={locale.requisite}
          />
        </div>
      </div>
      <div className={b('button')}>
        <Button type="submit">{locale.create}</Button>
      </div>
    </form>
  );
};

export default CreateRequisite;
