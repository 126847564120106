import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';

import CountryCodeSelector from './CountryCodeSelector/CountryCodeSelector';

import './Input.scss';

const b = block('input-phone-mobile');

const Input = ({ value, name = '', placeholder = '', isRequired = false, onChange, phoneCode, phoneChange }) => {
  return (
    <div className={b()}>
      <CountryCodeSelector activeValue={phoneCode} onChange={phoneChange} />

      <div className={b('tel')}>
        <input
          name={name}
          value={value}
          placeholder={placeholder || ''}
          onChange={onChange}
          className={b('native-element')}
          required={isRequired}
        />
      </div>
    </div>
  );
};

Input.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  phoneCode: PropTypes.string.isRequired,
  phoneChange: PropTypes.bool.isRequired,
};

export default Input;
