import { initialState } from '../initial';
import { actionTypes } from '../actions';

export function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return { ...state, actionProcessing: action.payload };

    case actionTypes.GET_TOPUP_REQUESTS_SUCCESS:
      return { ...state, history: action.payload.list, pages: action.payload.pages };

    default:
      return state;
  }
}
