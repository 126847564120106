// сафари не переваривает специфические форматы

// превращает 2022-01-01 в 01.01.2022
export const dateFormateForSafari = (date) => {
  if (!date) return '__.__.____';
  const [correctDate, time = ''] = date.split(' ');
  const timeSuffix = time ? ` ${time}` : '';
  const parts = correctDate.split('-');
  return `${parts[2]}.${parts[1]}.${parts[0]}${timeSuffix}`;
};
