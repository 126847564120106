import React from 'react';
import DatePicker from 'react-date-picker';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import './DateInput.scss';

const DateInput = ({ name, size = 'default', rounded, text, format, value, onChange, icon }) => {
  const b = block('wrapped-react-input');
  const calendarIcon = <SVGInline className={b('calendar-icon').toString()} svg={icon || ''} />;
  return (
    <div className={b({ name }, { sizable: size }, { rounded })}>
      {text && <p className={b('text')}>{text}</p>}
      <DatePicker name={name} format={format} value={value} onChange={onChange} calendarIcon={calendarIcon} />
    </div>
  );
};

DateInput.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
  format: PropTypes.string,
  rounded: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DateInput;