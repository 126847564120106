import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { statusItems } from 'features/withdrawal/helpers/data';
import arrowSVG from 'shared/img/arrow.svg';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';
import './TopUpHistoryItem.scss';

const b = block('top-up-item-desktop');

const TopUpHistoryItem = ({ card, setSelectedCard }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const { amount, paymentRequisite, paymentSystem, status, date, id, imageUrl, playerId } = card;
  const statusName = statusItems.find(item => item.value === status)?.name;

  const amountFormatted = formatNumberWithLimit(amount);

  return (
    <div className={b()}>
      <div className={b('top')}>
        <div className={b('item')}>
          <span className={b('item-title')}>ID</span>
          <span className={b('item-value')}>{`${id}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.playerId}`}</span>
          <span className={b('item-value')}>{playerId}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.date}`}</span>
          <span className={b('item-value')}>{`${date}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.bank[1]}`}</span>
          <span className={b('item-value')}>{`${paymentSystem}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.requisite}`}</span>
          <span className={b('item-value')}>{paymentRequisite}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.status}`}</span>
          <span className={b('item-value')}>{`${statusName}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{locale.amount}</span>
          <span className={b('item-value', { color: 'red' })}>{`${amountFormatted}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{locale.receipt}</span>
          <a
            className={b('item-value', { color: 'red' })}
            href={imageUrl}
            target="_blank"
          >
            {locale.receipt}
          </a>
        </div>
        {status !== 2 && status !== 3 && (
          <div className={b('edit')} onClick={() => setSelectedCard(card)}>
            {locale.edit}
            <SVGInline className={b('arrow').toString()} svg={arrowSVG} />
          </div>
        )}
      </div>
    </div>
  );
};

TopUpHistoryItem.propTypes = {
  card: PropTypes.object.isRequired,
  setSelectedCard: PropTypes.func.isRequired,
};

export default TopUpHistoryItem;
