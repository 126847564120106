export const initialState = {
  actionProcessing: false,
  banksRequisites: {},
  selectedRequisite: null,
  banks: [],
  activeRequests: [],
  activeRequestsPages: 0,
  requestHistory: {
    deposits: [
      // {
      //   id: 2,
      //   date: '2022-12-11 22:30:09',
      //   amount: 5,
      //   status: 2,
      //   comment: '',
      //   paymentSystem: 'bank name',
      //   paymentRequisite: '321315432523',
      //   imageUrl: 'url',
      //   transactionId: '09812676789312',
      // },
    ],
    withdrawals: [],
    pages: 0,
  },
  requestWithdrawalHistory: {
    withdrawals: [],
    pages: 0,
  },

  bankWithdrawals: [],
};
