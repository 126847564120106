import EnPNG from './img/en.png';
import MyPNG from './img/my.png';

export const languages = {
  EN: 'en-US',
  MY: 'my-MY',
};

export const languagesWithIcons = {
  [languages.EN]: { lang: languages.EN, icon: EnPNG, text: 'English' },
  [languages.MY]: { lang: languages.MY, icon: MyPNG, text: 'မြန်မာ' },
};

export const shortLanguages = {
  [languages.EN]: 'en',
  [languages.MY]: 'my',
};

const rtlLangs = [];

export const checkIsRtlLang = lang => {
  return rtlLangs.includes(lang);
};
