import React from 'react';
import block from 'bem-cn';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import SVG from 'components/SVG';
import { actions as authActions } from 'features/Auth';

import language from './img/lang.svg';
import pas from './img/pas.svg';
import out from './img/out.svg';
import arrowSvg from './img/arrow.svg';

import './Footer.scss';

const b = block('footer');

const Footer = ({ locale, isAuth }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(authActions.setAuthModal(true));
  }

  return (
    <footer className={b()}>
      <div className={b('title')}>{locale.other}</div>
      <section className={b('content')}>
        <Link to="/locale" className={b('item', { active: location.pathname.startsWith('/locale') })}>
          <SVG svgProps={{ svg: language }} className={b('item-img')} />
          <span className={b('item-text')}>{locale.changeLang}</span>
          <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
        </Link>
        {isAuth && (
          <Link to="/change-pas" className={b('item', { active: location.pathname.startsWith('/change-pas') })}>
            <SVG svgProps={{ svg: pas }} className={b('item-img')} />
            <span className={b('item-text')}>{locale.changePas}</span>
            <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
          </Link>
        )}
        {isAuth && (
          <div className={b('item')} onClick={handleLogOut}>
            <SVG svgProps={{ svg: out }} className={b('item-img')} />
            <span className={b('item-text')}>{locale.logout}</span>
            <SVG className={b('element-arrow')} svgProps={{ svg: arrowSvg }} />
          </div>
        )}
      </section>
    </footer>
  );
};

export default Footer;
