import BaseApi from '../BaseApi';
import { NotifyPaymentPendingApiConverter } from './NotifyPaymentPendingApiConverter';

class NotifyPaymentPendingApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new NotifyPaymentPendingApiConverter();
  }

  getNotifyPaymentPending = () =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/unprocessed_payments`,
      null,
      null,
      this.converter.convertNotifyPaymentPending,
    );
}

export default NotifyPaymentPendingApi;
