import BaseApi from '../BaseApi';
import { CashierJackpotConverter } from './CashierJackpotConverter';

class CashierJackpotApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new CashierJackpotConverter();
  }

  getCashierJackpot = ({ date_from, date_to }) =>
    this.sendQuery(
      this.queryTypes.POST,
      `${this.baseUrl}/get_cashier_jackpots`,
      { date_from, date_to },
      null,
      this.converter.cashierJackpotConverter
    );
}

export default CashierJackpotApi;
