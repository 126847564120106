export default [
  {
    textId: 'cashRegister',
    items: [
      {
        textId: 'createUser',
        route: '/user-create',
        roles: [1, 2, 3, 4, 5, 6],
      },
      {
        textId: 'balances',
        route: '/users-list',
        roles: [1, 2, 3, 4, 5, 6],
      },
      {
        textId: 'cashiersTransactions',
        route: '/total',
        roles: [1, 2, 3, 4, 5, 6],
      },
      /* {
        textId: 'totalBalance',
        route: '/totalBalance',
        roles: [1, 2, 3, 4, 5, 6],
      }, */
      /* {
        textId: 'bets',
        route: '/bets',
        roles: [1, 2, 3, 4, 5, 6],
      }, */
      {
        textId: 'casinoHistory',
        route: '/casino-history',
        roles: [1, 2, 3, 4, 5, 6],
      },
      {
        textId: 'bank',
        route: '/requisites',
        roles: [1],
      },
      {
        textId: 'withdrawalRequests',
        route: '/withdrawal-history',
        roles: [1],
      },

      {
        textId: 'pendingTopup',
        route: '/pending-top-up',
        roles: [1],
        withIndicatorTopUp: true,
      },
      {
        textId: 'pendingWithdrawal',
        route: '/pending-withdrawal',
        roles: [1],
        withIndicatorWithdrawal: true,
      },
      {
        textId: 'topUpRequests',
        route: '/top-up-history',
        roles: [1],
      },
    ],
  },
  {
    textId: 'partners',
    items: [
      {
        textId: 'transactionHistory',
        route: '/transaction-history',
        roles: [1, 2, 3, 4, 5, 6],
      },
    ],
  },
  {
    textId: 'treeMenu',
    items: [
      {
        textId: 'tree',
        route: '/tree',
        roles: [1, 2, 3, 4, 5, 6],
      },
    ],
  },
];
