import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import asyncPoll from 'react-async-poll';

import { actions as notifyPaymentPendingActions } from 'features/notifyPaymentPending';

const AsyncPollNotify = () => null;

function mapStateToProps(state) {
  return {
    isAuth: state.auth.isAuth,
    role: state.auth.role,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getNotifyPaymentPending: notifyPaymentPendingActions.getNotifyPaymentPending,
  };
  return bindActionCreators(actions, dispatch);
}

function onPollInterval(props) {
  if (props.isAuth && props.role === 1) {
    props.getNotifyPaymentPending();
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(asyncPoll(5 * 1000, onPollInterval)(AsyncPollNotify));
