export class NotifyPaymentPendingApiConverter {
  convertNotifyPaymentPending = ({ unprocessed_payments }) => {
    return unprocessed_payments.map(t => {
      return {
        id: t?.id,
        userID: t?.user_id,
        username: t?.username,
        amount: t?.amount,
        paymentType: t?.payment_type,
        key: `${t?.id}_${t?.payment_type}`,
      };
    });
  };
}
