import KBZ_Pay from './kbz-pay.png';
import Wave_Money from './wave-money.png';
import CB_Pay from './cb-pay.png';
import AYA_Pay from './aya-pay.png';
import KBZ_mBanking from './kbz.png';
import AYA_mBanking from './aya-bank.png';
import CB_mBanking from './cb-bank.png';

const bankLogos = {
  KBZ_Pay,
  Wave_Money,
  CB_Pay,
  AYA_Pay,
  KBZ_mBanking,
  AYA_mBanking,
  CB_mBanking,
};

export { bankLogos };
