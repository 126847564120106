import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions as notifyActions } from 'features/notifyPaymentPending';

import Notification from './Notification';
import './Notify.scss';

class Notify extends React.PureComponent {
  static propTypes = {
    notifications: PropTypes.array.isRequired,

    deleteNotify: PropTypes.func.isRequired,
  };

  render() {
    const { notifications, deleteNotify, locale } = this.props;
    const items = notifications.map(item => (
      <Notification key={item.key} item={item} deleteNotify={deleteNotify} locale={locale} />
    ));
    return ReactDOM.createPortal(items, document.getElementById('notify2-root'));
  }
}

function mapStateToProps(state) {
  return {
    notifications: state.notifyPaymentPending.notifications,
    locale: state.locale.locale,
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    deleteNotify: notifyActions.deleteNotify,
  };
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notify);
