import React, { useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';

import { actions } from 'features/PaymentAdmin/redux';
import BankItem from './BankItem/BankItem';
import './RequisitesList.scss';

const b = block('requisites-list');

const RequisitesList = () => {
  const banksRequisites = useSelector(state => state.paymentAdmin.banksRequisites);
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getRequisites());
  }, [dispatch]);

  const items = Object.entries(banksRequisites).map(([bank, requisites]) => (
    <BankItem key={bank} bank={bank} requisites={requisites} />
  ));

  return (
    <article className={b()}>
      <ul className={b('banks-list')}>{items}</ul>
      {Boolean(!items.length) && <h3 className={b('title')}>{locale.noRequisites}</h3>}
      <div className={b('button')}>
        <Button link="/requisites-create">{locale.createRequisite}</Button>
      </div>
    </article>
  );
};

export default RequisitesList;
