export class BetsApiConverter {
  convertTransferList = ({ data, totalCount }) => {
    return {
      count: totalCount,
      transfers: data.map(t => {
        const status = { 1: 'win', 2: 'bet', 3: 'refund' }[t.status];
        return {
          value: t?.amount,
          id: t?.id,
          type: t?.gameType,
          userId: t?.userId,
          provider: t?.provider,
          status,
          createdAt: t?.date,
          balanceBefore: t?.balanceBefore,
        };
      }),
    };
  };
}
