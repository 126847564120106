import React, { useState } from 'react';
import block from 'bem-cn';
import dayjs from 'dayjs';

import SVG from 'components/SVG';

import calendarSVG from './img/calendar.svg';
import arrowSVG from './img/arrow.svg';

import './InputDate.scss';

const b = block('input-date-desktop');

const InputDateNative = ({ onChange, value, disabled = false }) => {
  const [formattedValue, setFormattedValue] = useState(value
    ? dayjs(value).format('DD.MM.YYYY  HH:mm:ss')
    : 'DD.MM.YYYY  HH:mm:ss'
  );

  const handleDateChange = e => {
    setFormattedValue(dayjs(e.currentTarget.value).format('DD.MM.YYYY  HH:mm:ss'));
    onChange(e.currentTarget.value);
  }

  return (
    <label className={b({ disabled })}>
      <SVG className={b('icon')} svgProps={{ svg: calendarSVG }} />
      <input
        type="datetime-local"
        className={b('native')}
        value={value}
        step={1}
        onChange={handleDateChange}
        disabled={disabled}
      />
      <div className={b('value')}>{formattedValue}</div>
      <SVG className={b('arrow')} svgProps={{ svg: arrowSVG }} />
    </label>
  );
};

export default InputDateNative;
