import React, { useState, useEffect, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator/desktop';
import Button from 'components/Button';
import Select from 'components/Select';
import InputDate from 'components/InputDate';

import { actions } from 'features/PaymentAdmin/redux';
import RequestCard from '../ActiveAdminRequests/RequestCard/RequestCard';
import './RequestsHistory.scss';

const b = block('admin-requests-history');

const itemsOnPage = 10;
const allStatuses = [0, 1, 2, 3, 4];

const RequestsHistory = () => {
  const actionProcessing = useSelector(state => state.paymentAdmin.actionProcessing);
  const requestHistory = useSelector(state => state.paymentAdmin.requestHistory);
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();

  const statuses = useMemo(() => {
    return Object.keys(allStatuses).reduce(
      (acc, status) => {
        acc.push({ name: locale.statuses[status], value: status });
        return acc;
      },
      [{ name: locale.allStatuses, value: '' }],
    );
  }, [locale]);

  const [activeStatus, setActiveStatus] = useState({ name: locale.allStatuses, value: '' });

  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState(dayjs().add(-7, 'day').$d);
  const [endDate, setEndDate] = useState(dayjs().$d);

  const handleFormSubmit = useCallback(() => {
    dispatch(
      actions.getRequestsHistory({
        page,
        from: dayjs(startDate).format('YYYY-MM-DD HH:mm:ss'),
        to: dayjs(endDate)
          .add(1, 'day')
          .format('YYYY-MM-DD HH:mm:ss'),
        count: itemsOnPage,
        status: activeStatus.value,
      }),
    );
  }, [startDate, endDate, page, dispatch, activeStatus]);

  useEffect(() => {
    handleFormSubmit();
  }, [page]);

  const items = requestHistory.deposits.map(item => (
    <div className={b('item')} key={item.id}>
      <RequestCard item={item} />
    </div>
  ));

  const handleSelectBank = value => {
    setActiveStatus(statuses.find(item => item.value === value));
  };

  return (
    <article className={b()}>
      <Spinner isLoading={actionProcessing} />
      <div className={b('filter')}>
        <div className={b('filter-item')}>
          <div className={b('filter-title')}>{locale.from}</div>
          <div className={b('input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(startDate)}
              onChange={date => {
                setStartDate(dayjs(date).format('YYYY-MM-DD'));
              }}
              isValid={Boolean(startDate)}
              title="date title"
            />
          </div>
        </div>
        <div className={b('filter-item')}>
          <div className={b('filter-title')}>{locale.to}</div>
          <div className={b('input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(endDate)}
              onChange={date => {
                setEndDate(dayjs(date).format('YYYY-MM-DD'));
              }}
              isValid={Boolean(endDate)}
              title="date title"
            />
          </div>
        </div>
        <div className={b('filter-item')}>
          <Select items={statuses} activeItem={activeStatus} onChange={handleSelectBank} />
        </div>
        <div className={b('button')}>
          <Button onClick={handleFormSubmit}>{locale.apply}</Button>
        </div>
      </div>
      <section className={b('content')}>
        {items}
        <div className={b('paginator')}>
          <Paginator currentPage={page} pagesCount={requestHistory.pages} onPageClick={setPage} />
        </div>
      </section>
    </article>
  );
};

export default RequestsHistory;
