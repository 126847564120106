/* eslint-disable no-continue */
import React, { useMemo } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import ArrowSVG from 'shared/img/arrow-big-down.svg';

import './Paginator.scss';

const b = block('paginator-desktop');

const Paginator = ({ count, currentPage, onPageClick }) => {
  const list = useMemo(() => {
    let correctList;
    switch (true) {
      case count > 5 && currentPage < 3:
        correctList = [1, 2, 3, 4, '...', count]; break;
      case count > 6 && currentPage > 1 && currentPage < count - 3:
        correctList = [1, '...', currentPage, currentPage + 1, currentPage + 2, '...', count ]; break;
      case count > 5 && currentPage > count - 4:
        correctList = [1, '...', count - 3, count - 2, count - 1, count]; break;
      default: correctList = (new Array(count)).fill(0).map((_, i) => i + 1);
    }
    return correctList.map((val, i) => (
      <li
        key={i}
        className={b('item')}
        onClick={val === '...' || val + 1 === currentPage ? null : () => onPageClick(+val - 1)}
      >
        {val}
      </li>
    ));
  }, [count, currentPage, onPageClick]);

  return (
    <div className={b()}>
      <SVGInline
        className={b('arrow', { left: true }).toString()}
        svg={ArrowSVG}
        onClick={currentPage === 0 ? null : () => onPageClick(currentPage - 1)}
      />
      <ul className={b('list')}>{list}</ul>
      <SVGInline
        className={b('arrow', { right: true }).toString()}
        svg={ArrowSVG}
        onClick={currentPage === count - 1 ? null : () => onPageClick(currentPage + 1)}
      />
    </div>
  );
};

Paginator.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,

  onPageClick: PropTypes.func.isRequired,
};

export default Paginator;
