import {useEffect, useState} from "react";

const useProviderFilters = (locale) => {

	let staticFilters = {
		betType: {name: locale.all , value: 'all'},
		provider: {name: locale.all, value: 'all'},
		gameType: {name: locale.all, value: 'all'},
	}

	const [filters, setFilters] = useState({...staticFilters});

	useEffect(() => {
		setFilters({
			...filters,
			provider: {name: locale.all, value: 'all'},
		});
	}, [filters.gameType])


	const initialFilters = filters.betType.name ? filters : staticFilters

	return {filters: initialFilters, setFilters}
}

export default useProviderFilters