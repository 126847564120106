import BaseApi from '../BaseApi';
import { BetsApiConverter } from './BetsApiConverter';

class BetsApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new BetsApiConverter();
  }

  getTransfers = params =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/bet_history?${params}`,
      null,
      null,
      this.converter.convertTransferList,
    );
}

export default BetsApi;
