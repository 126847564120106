import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import Button from 'components/Button';
import crossSVG from 'shared/img/cross.svg';
import { statusItems } from 'features/topUp/helpers/data';
import { actions as topUpActions } from 'features/topUp';
import { formatNumberWithLimit } from 'shared/utils/formatNumber';

import './TopUpCard.scss';
import { useState } from 'react';

const b = block('top-up-card-desktop');

const TopUpCard = ({ card, closeFunction, callback }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const dispatch = useDispatch();

  const handleButtonClick = status => {
    dispatch(topUpActions.resolveTopUpCard({ id: card.id, status, amount: card.amount }, callback));
    closeFunction();
  };

  const amountFormatted = formatNumberWithLimit(card.amount);

  return (
    <div className={b()}>
      <div className={b('header')}>
        <h3 className={b('title')}>{locale.request}</h3>
        <SVGInline className={b('cross').toString()} svg={crossSVG} onClick={closeFunction} />
      </div>
      <div className={b('body')}>
        <div className={b('item')}>
          <span className={b('item-title')}>ID</span>
          <span className={b('item-value')}>{`${card.id}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.playerId}`}</span>
          <span className={b('item-value')}>{`${card.playerId}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.date}`}</span>
          <span className={b('item-value')}>{`${card.date}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.bank[1]}`}</span>
          <span className={b('item-value')}>{`${card.paymentSystem}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.requisite}`}</span>
          <span className={b('item-value')}>{card.paymentRequisite}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{`${locale.status}`}</span>
          <span className={b('item-value')}>{`${statusItems.find(item => item.value === card.status)?.name}`}</span>
        </div>
        <div className={b('item')}>
          <span className={b('item-title')}>{locale.amount}</span>
          <span className={b('item-value', { color: 'red' })}>{`${amountFormatted}`}</span>
        </div>
      </div>
      <section className={b('data')}>
        <div className={b('button')}>
          <Button color="bordered" onClick={() => handleButtonClick(1)}>
            {locale.approve}
          </Button>
        </div>
        <div className={b('button')}>
          <Button onClick={() => handleButtonClick(2)}>{locale.decline}</Button>
        </div>
      </section>
    </div>
  );
};

TopUpCard.propTypes = {
  card: PropTypes.object.isRequired,
  closeFunction: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

export default TopUpCard;
