import React, { useState, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions } from 'features/PaymentAdmin/redux';
import Spinner from 'components/Spinner';
import PopUp from 'components/PopUp';
import Paginator from 'components/Paginator/desktop';
import Input from 'components/Input/desktop';
import Button from 'components/Button';
import SwitchBox from 'components/SwitchBox';

import RequestCard from './RequestCard/RequestCard';
import DetailCard from './DetailCard/DetailCard';
import './ActiveAdminRequests.scss';

const b = block('active-admin-requests');

const itemsOnPage = 10;

const ActiveAdminRequests = () => {
  const actionProcessing = useSelector(state => state.paymentAdmin.actionProcessing);
  const activeRequests = useSelector(state => state.paymentAdmin.activeRequests);
  const activeRequestsPages = useSelector(state => state.paymentAdmin.activeRequestsPages);
  const locale = useSelector(state => state.locale.locale);
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [activeCard, setActiveCard] = useState(null);
  const [transactionId, setTransactionId] = useState('');
  const [autoUpdateOn, setAutoUpdateOn] = useState(false);

  useEffect(() => {
    dispatch(actions.getActiveRequests({ page, count: itemsOnPage }));
  }, [dispatch, page]);

  const items = activeRequests.map(item => (
    <div className={b('item')} key={item.id}>
      <RequestCard item={item} callback={() => setActiveCard(item)} withButton />
    </div>
  ));

  const callback = () => {
    setActiveCard(null);
    dispatch(actions.getActiveRequests({ page, count: itemsOnPage }));
  };

  useEffect(() => {
    dispatch(actions.getActiveRequests({ page, count: itemsOnPage, transactionId }));
  }, [dispatch, page]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (autoUpdateOn) {
        dispatch(actions.getActiveRequests({ page, count: itemsOnPage, transactionId }));
      }
    }, 10000);

    return () => clearInterval(timer);
  }, [dispatch, autoUpdateOn, page, transactionId]);

  const handleButtonClick = () => {
    dispatch(actions.getActiveRequests({ page, count: itemsOnPage, transactionId }));
  };

  return (
    <article className={b()}>
      <Spinner isLoading={actionProcessing} />
      {activeCard && (
        <PopUp onClose={() => setActiveCard(null)}>
          <DetailCard item={activeCard} callback={callback} />
        </PopUp>
      )}
      <section className={b('content')}>
        <div className={b('block')}>
          <div className={b('label')}>{locale.idOfTheTransaction}</div>
          <Input value={transactionId} onChange={e => setTransactionId(e.currentTarget.value)} />
        </div>
        <div className={b('switch')}>
          {locale.autoUpdate}
          <div className={b('switch-wrapper')}>
            <SwitchBox isActive={autoUpdateOn} callBack={() => setAutoUpdateOn(!autoUpdateOn)} />
          </div>
        </div>
        <div className={b('button')}>
          <Button text={locale.apply} callBack={handleButtonClick} />
        </div>
        {items}
        <div className={b('paginator')}>
          <Paginator currentPage={page} pagesCount={activeRequestsPages} onPageClick={setPage} />
        </div>
      </section>
    </article>
  );
};

export default ActiveAdminRequests;
