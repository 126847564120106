import React, { useMemo, useEffect, useState, useCallback } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Spinner from 'components/Spinner';
import Paginator from 'components/Paginator/desktop';
import useTopUpRequests from 'features/topUp/helpers/useTopUpRequests';
import { itemsOnPage } from 'features/topUp/helpers/data';

import TopUpCard from './TopUpCard/TopUpCard';
import TopUpHistoryItem from './TopUpHistoryItem';
import TopUpFilter from './TopUpFilter';

import './TopUp.scss';

const b = block('top-up-desktop');

const TopUp = () => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { actionProcessing } = useSelector(state => state.slotsBetHistory, shallowEqual);

  const filterData = useTopUpRequests();
  const { items, state, pageCount, write, onSubmit } = filterData;
  const { page } = state;

  const [selectedCard, setSelectedCard] = useState(null);

  const itemsElements = useMemo(
    () =>
      items.map(t => (
        <div key={t.key} className={b('item')}>
          <TopUpHistoryItem card={t} setSelectedCard={setSelectedCard} />
        </div>
      )),
    [items],
  );

  return (
    <div className={b()}>
      {selectedCard !== null && (
        <div className={b('modal')}>
          <TopUpCard card={selectedCard} closeFunction={() => setSelectedCard(null)} callback={onSubmit} />
        </div>
      )}
      <Spinner isLoading={actionProcessing} />
      <h3 className={b('title')}>{locale.topUp}</h3>
      <div className={b('content')}>
        <div className={b('filters')}>
          <TopUpFilter filterData={filterData} />
        </div>
        <div className={b('list')}>{itemsElements}</div>
        <div className={b('paginator-container')}>
          <div className={b('paginator')}>
            <Paginator count={pageCount} currentPage={page} onPageClick={value => write(value, 'page')} />
          </div>
        </div>
      </div>
    </div>
  );
};

TopUp.propTypes = {};

export default TopUp;
