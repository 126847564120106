/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';

import './SVG.scss';

const b = block('SVG-component');

const SVG = ({ svgProps, containerClassName = '', svgClassName = '', ...restProps }) => {
  return (
    <span className={b('container').mix(containerClassName)} {...restProps}>
      <SVGInline {...svgProps} className={b('content').mix(svgClassName).toString()} />
    </span>
  );
};

export default SVG;
