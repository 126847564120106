import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return {
        ...state,
        actionProcessing: action.payload,
      };

    case actionTypes.GET_REQUISITES_SUCCESS:
      return {
        ...state,
        banksRequisites: action.payload,
      };

    case actionTypes.SET_SELECTED_REQUISITE:
      return {
        ...state,
        selectedRequisite: action.payload,
      };

    case actionTypes.GET_BANKS_SUCCESS:
      return {
        ...state,
        banks: action.payload,
      };

    case actionTypes.GET_ACTIVE_REQUESTS_SUCCESS:
      return {
        ...state,
        activeRequests: action.payload.activeRequests,
        activeRequestsPages: action.payload.pages,
      };

    case actionTypes.GET_REQUESTS_HISTORY_SUCCESS:
      return {
        ...state,
        requestHistory: action.payload,
      };

    case actionTypes.GET_WITHDRAWAL_REQUESTS_HISTORY_SUCCESS:
      return {
        ...state,
        requestWithdrawalHistory: action.payload,
      };

    case actionTypes.GET_BANK_WITHDRAWALS:
      return { ...state, bankWithdrawals: action.payload };

    default:
      return { ...state };
  }
};
