import React, { useState } from 'react';
import block from 'bem-cn';
import PT from 'prop-types';
import { useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { useHistory } from 'react-router-dom';

import { bankLogos } from 'features/PaymentAdmin/img';
import { bankNames } from 'features/PaymentAdmin/data';
import arrowSVG from 'features/PaymentAdmin/img/arrow.svg';

import { actions } from 'features/PaymentAdmin/redux';
import './BankItem.scss';

const b = block('bank-item');

const BankItem = ({ bank, requisites }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const handleBankClick = () => {
    setIsOpen(!isOpen);
  };

  const handleRequisiteClick = ({ id, requisite }) => {
    dispatch(actions.setSelectedRequisite({ id, requisite, bank }));
    history.push('/requisites-detail');
  };

  const items = requisites.map(({ id, requisite }) => (
    <li className={b('requisite')} key={id} onClick={() => handleRequisiteClick({ id, requisite })}>
      <div className={b('value')}>{requisite}</div>
      <SVGInline className={b('arrow', { type: 'requisite' }).toString()} svg={arrowSVG} />
    </li>
  ));

  return (
    <article className={b()}>
      <section className={b('bank')} onClick={handleBankClick}>
        <img className={b('logo')} src={bankLogos[bank]} alt="" />
        <div className={b('name')}>{bankNames[bank] ?? bank}</div>
        <SVGInline className={b('arrow', { open: isOpen }).toString()} svg={arrowSVG} />
      </section>
      <ol className={b('requisites-list')}>{isOpen && items}</ol>
    </article>
  );
};

BankItem.propTypes = {
  bank: PT.string.isRequired,
  requisites: PT.array.isRequired,
};

export default BankItem;
